export const coffeeShopPosHero = {
    title: "Coffee shop POS system",
    subtext:
      "Sell more espressos, cappuccinos, and mochas with a POS that's perfect for your coffee shop.",
    PrimaryCta: {
      ctaTitle: "Learn more",
      ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
    },
  };

  export const coffeeShopPosSubHero = {
    title: "Expedite coffee without friction",
    subtext:
      "Your customers want their caffeine now. With SpotOn, you can give it to them. Our coffee shop POS combines intuitive ordering with ultra-fast payments so your baristas can spend less time placing orders and more time making them.",
    PrimaryCta: {
      ctaTitle: "Learn more",
      ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
    },
  };
  
  export const coffeeShopPosWhiteGlove = {
    mainTitle: "All the help. All the time",
    title: "Support as robust as your darkest roast",
    content:
      "Just like a strong cup of joe, our expert customer support keeps you going all day, every day. With local representatives and 24/7/365 technical phone support, you can keep your cafe up and running without any downtime.",
    ctaInfoPrimary: {
      ctaTitle: "Learn more",
      ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
    },
  };

  export const coffeeShopPosMultiSection = {
    sectionTitle: "The perfect blend of technology",
    featureBlocks: [
      {
        blockTitle: "Start with a POS",
        blockSubTitle:
          "Simplify your operations with a POS system for coffee shops. SpotOn Restaurant has all the features for your cafe to work smoothly and integrates seamlessly with all our restaurant solutions.",
        blockList: [
          "Lightning-fast ordering & payments",
          "Custom menu build with limitless modifiers",
          "Real-time updates & insightful visual reports",
          "A remote back office accessible from a phone",
          "Multi-location management in one place",
        ],
        blockImg: "coffee-shop-pos-1.png",
        ImgAltTag: "coffe shop pos",
        forceReverse: false,
        ctaPrime: {
          ctaTitle: "Learn more",
          ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
        },
      },
      {
        blockTitle: "Build a loyal customer base",
        blockSubTitle:
          "Entice coffee-lovers back into your shop through loyalty. SpotOn Loyalty connects with our restaurant POS making it easy for customers to enroll in your program, earn points, and redeem their rewards without slowing down the line.",
        blockImg: "coffee-shop-pos-2.png",
        ImgAltTag: "coffee shop pos",
        blockList: [
          "Create rewards unique to your cafe",
          "Enroll customers easily during checkout",
          "Automatically collect customer information",
          "Send marketing emails with coffee deals",
          "View analytics on rewards and campaigns",
        ],
        forceReverse: false,
        ctaPrime: {
          ctaTitle: "Learn more",
          ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
        },
      },
      {
        blockTitle: "Eliminate lines with QR",
        blockSubTitle:
          "Long lines discourage customers. Make them disappear with QR codes. Through a scannable code in your shop, SpotOn QR empowers customers to order from their phones the moment they step into your coffeehouse.",
        blockList: [
          "Give customers instant access to your online menu",
          "Enable customers to customize and place orders",
          "Automatically upsell with add-on suggestions",
          "Accept payments through card, Apple Pay, or Google Pay"
        ],
        blockImg: "coffee-shop-pos-3.png",
        ImgAltTag: "coffee shop pos",
        forceReverse: false,
        ctaPrime: {
          ctaTitle: "Learn more",
          ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
        },
      },
      {
        blockTitle: "Make online ordering work for you",
        blockSubTitle:
          "Create additional streams of revenue with SpotOn Order. Let customers effortlessly order on your website, Google, or the SpotOn app and take ownership of your marketing data along with the entire ordering process.",
        blockImg: "coffee-shop-pos-4.png",
        ImgAltTag: "coffee shop pos",
        blockList: [
          "Get a commission-free ordering menu",
          "Update every menu from one place",
          "Throttle orders when you get busy",
          `Offer flat-rate <a href="/products/online-order-and-delivery/" target="_blank">delivery with DoorDash</a>`,
          "Boost holiday sales with e-gift cards",
        ],
        forceReverse: false,
        ctaPrime: {
          ctaTitle: "Learn more",
          ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
        },
      },
      {
        blockTitle: "Visualize your coffee data",
        blockSubTitle:
          "Seeing is believing when it comes to reporting. With the SpotOn Dashboard, you're able to access and view your reports through graphs, charts, and tables so you can quickly make data-driven decisions for your coffee bar.",
        blockList: [
          "See in-house and online sales together",
          "View your coffee sales trends over time",
          "Set up and receive manager alerts",
          "Get all your reporting data on mobile"
        ],
        blockImg: "coffee-shop-pos-5.png",
        ImgAltTag: "coffee shop pos",
        forceReverse: false,
        ctaPrime: {
          ctaTitle: "Learn more",
          ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
        },
      },
    ],
  };

  export const coffeeShopPosCTA = {
    title: "Book your consultation.",
    ctaImg: "cta-people.png",
    ctaInfo: {
      ctaTitle: "Learn more",
      ctaTarget: "/restaurant-pos/coffee-shop-pos/demo",
    },
  };

  export const coffeeShopPosVideo = {
    title: "See SpotOn solutions in action",
    videoBtn: "Watch video",
    posterImg: "coffe-pos-poster.png",
    videoUrlId: "EbacP7r-Mx0",
  };
  
  export const coffeeShopPosTestimonials = {
    title: "What restaurants are saying about SpotOn",
    reviews: [
      {
        quote:
          "With SpotOn, I can give my accountant access to my reports, which is awesome for monthly bookkeeping. It makes their life easy to have everything in one system, versus them missing information and us having to calculate. It’s a huge time saver and makes a big difference for our end-of-year taxes.",
        reviewDate: "April 21, 2022",
        reviewer: "Natacha Legein",
        reviewerBio: "Owner, Crepe Corner",
        rating: "",
        reviewerPhoto: "crepe-corner-logo.png",
      },
      {
        quote:
          "Changing the menu and prices in the SpotOn back-of-house is simple. I can do it from anywhere. I've been out of town and made changes on our POS, which saves me a lot of time.",
        reviewDate: "March 3, 2022",
        reviewer: "Jonathan Katsiros",
        reviewerBio: "CFO, Blue Ridge Best Foods Inc.",
        rating: "",
        reviewerPhoto: "omelet-shoppe-logo.png",
      },
      {
        quote:
          "It was hands-down super easy. It took one time to show Sherri how to change the menu, and she had no problem whatsoever. Changing pricing for events is instant. The technology works well.",
        reviewDate: "April 4, 2022",
        reviewer: "Dave DeLovely",
        reviewerBio: "Owner, DeLovely’s Delicious Mini Donuts",
        rating: "",
        reviewerPhoto: "delovely-delicious-logo.png",
      },
      {
        quote:
          "SpotOn Order has not only saved us money and improved our margins on the labor front, but it has also made things so much more streamlined and accurate in the kitchen. We have less errors and less waste. It's a huge improvement.",
        reviewDate: "June 24, 2022",
        reviewer: "Sam Josi",
        reviewerBio: "Co-Owner, Blue Barn",
        rating: "",
        reviewerPhoto: "blue-barn-logo.png",
      },
    ],
  };
  
  export const coffeeShopPosCar = {
    title: "Run a different type of restaurant",
    subtext: "Coffee Shops are just one of our specialties.",
    punctuation: "?",
    slides: [
      {
        bg: "quick-dining.png",
        cardTitle: "Quick service restaurant",
        cardContent: "Work faster and smarter",
        icon: "qsr.png",
        linkTo: "/quick-service-pos",
      },
      {
        bg: "fine-dining.png",
        cardTitle: "Fine Dining",
        cardContent: "Tailor-made restaurant solutions",
        icon: "fine-dining.png",
        linkTo: "/fine-dining-pos",
      },
      {
        bg: "food-truck.png",
        cardTitle: "Food Trucks",
        cardContent: "Tech that can keep up with your food truck",
        icon: "food-trucks.png",
        linkTo: "/food-truck-pos",
      },
      {
        bg: "casual-dining.png",
        cardTitle: "Casual dining",
        cardContent: "Unlock your restaurant’s potential",
        icon: "casual-dining.png",
        linkTo: "/casual-dining-pos",
      },
    ],
  };
  