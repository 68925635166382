import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import Image from "../../../components/ImageQuerys/RandomImages";
import {
  coffeeShopPosHero,
  coffeeShopPosSubHero,
  coffeeShopPosWhiteGlove,
  coffeeShopPosMultiSection,
  coffeeShopPosCTA,
  coffeeShopPosVideo,
  coffeeShopPosTestimonials,
  coffeeShopPosCar,
} from "../../../data/coffee-shop-pos-data";
import { pizzaBadges } from "../../../data/pizza-data";
import {
  CrepeCornerSlides,
  heroSuccess,
} from "../../../data/success-stories-data";

import heroBg from "../../../images/hero-images/coffee-shop-pos-hero.png";

const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const Badges = loadable(() =>
  import("../../../components/ConversionPath/Badges")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "coffee-shop-pos-white-glove.png";

const CoffeeShopPosPage = () => {
  return (
    <Layout>
      <SEO
        title="Coffee Shop POS System | Point-of-Sale Software for Coffee Shops | SpotOn"
        description="Our coffee shop POS system takes orders and payments quickly so your cafe can run smoothly and serve more customers. Get a seamless point-of-sale solution. Learn more today!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/restaurant-pos/coffee-shop-pos/"
        productSchemaSR
      />
      <BHero
        className="xl:mx-10"
        sectionData={coffeeShopPosHero}
        heroBg="coffee-shop-pos-hero.png"
      />
      <div>
        <Image
          className="mx-auto my-[60px] max-w-[350px]"
          imageName="coffee-shop-pos-logos.png"
          objectFit="scale-down"
        />
      </div>
      <NoVisualsCustom sectionData={coffeeShopPosSubHero} simple />
      <WhiteGlove
        sectionData={coffeeShopPosWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <Badges items={pizzaBadges} />
      <LargeFeatures
        sectionData={coffeeShopPosMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={coffeeShopPosCTA} />
      <SuccessStories sectionData={heroSuccess} slides={CrepeCornerSlides} />
      <VideoSection sectionData={coffeeShopPosVideo} />
      <TestmonialReviews sectionData={coffeeShopPosTestimonials} />
      <BusinessTypes sectionData={coffeeShopPosCar} />
    </Layout>
  );
};

export default CoffeeShopPosPage;
